/*
	*******************
	Template name:  covidland
	Version:        1.0
	Author:         ThemeLooks
	Author url:     http://themelooks.com

	NOTE:
	-----
	Please DO NOT EDIT THIS CSS, you may need to use "custom.css" file for writing your custom css.
	We may release future updates so it will overwrite this file. it's better and safer to use "custom.css".

    ******** CSS INDEX ********
    01. Base
        1.1 Reset 
        1.2 Grid
        1.3 Colors
    02. Components
        2.1 Common Classes
        2.2 Buttons
        2.3 Input
        2.4 Menu 
        2.5 Section Title
        2.6 Features
        2.7 Card
        2.8 Widget
        2.9 Page Title
    03. Layout
        3.1 Header
        3.2 Banner
        3.3 Updates
        3.3 Blog
        3.4 Footer
        3.5 404
    04. Sections
        4.1 Symptoms
        4.2 Contact

    ********************/
    
// IMPORT ALL DIRECTORY SASS FILE
@import '00-abstracts/00-abstracts-dir';
@import '01-base/00-base-dir';
@import '02-components/00-component-dir';
@import '03-layout/00-layout-dir';
@import '04-section/00-section-dir';