@charset "UTF-8";
/*
	*******************
	Template name:  covidland
	Version:        1.0
	Author:         ThemeLooks
	Author url:     http://themelooks.com

	NOTE:
	-----
	Please DO NOT EDIT THIS CSS, you may need to use "custom.css" file for writing your custom css.
	We may release future updates so it will overwrite this file. it's better and safer to use "custom.css".

    ******** CSS INDEX ********
    01. Base
        1.1 Reset 
        1.2 Grid
        1.3 Colors
    02. Components
        2.1 Common Classes
        2.2 Buttons
        2.3 Input
        2.4 Menu 
        2.5 Section Title
        2.6 Features
        2.7 Card
        2.8 Widget
        2.9 Page Title
    03. Layout
        3.1 Header
        3.2 Banner
        3.3 Updates
        3.3 Blog
        3.4 Footer
        3.5 404
    04. Sections
        4.1 Symptoms
        4.2 Contact

    ********************/
/* ************************
   01.1: Reset
   ********************* */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  outline: none !important;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.625;
  color: #666666;
  text-align: left;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden !important;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-weight: 700;
  line-height: 1;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  margin-top: 0;
  margin-bottom: 20px;
}

p:last-child {
  margin-bottom: 0;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #111111;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #CC0C1B;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

::selection {
  text-shadow: none;
  color: #ffffff;
}

::placeholder {
  color: #707070 !important;
  opacity: 1 !important;
}

iframe {
  max-width: 100%;
  border: none;
  display: block;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none !important;
}

button,
input[type="submit"] {
  border: none;
  background-color: transparent;
  cursor: pointer;
  -webkit-appearance: button;
  -moz-appearance: button;
}

textarea {
  resize: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* ************************
   01.2: Grid
   ********************* */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

/* ************************
   01.3: Color
   ********************* */
.main-menu .nav > li > a.mPS2id-highlight-last, .main-menu .nav li.current-menu-parent > a, .main-menu .nav li.current-menu-item > a, .section-title span, .card .card-body span, .widget ul li a:hover, .corona-live-update .live-update-title h2 span:first-child {
  color: #CC0C1B;
}

::selection, .btn, .btn.btn-borderd:hover, .btn-icon .icon, .main-menu #menu-button.menu-opened span:before, .main-menu #menu-button.menu-opened span:after, .widget.widget-social-icon li a:hover, .pagination li a.current, .pagination li a:hover {
  background-color: #CC0C1B;
}

.btn.btn-borderd:hover {
  border-color: #CC0C1B !important;
}

h1,
h2,
h3,
h4,
h5,
h6, .theme-input-lable, .corona-live-update .live-update-title > span, .corona-live-update .cu_info span, .coronaDataTableWrapper .dataTables_length,
.coronaDataTableWrapper .dataTables_filter label, .coronaDataTableWrapper .dataTables_paginate .paginate_button, table.coronaDataTable thead th {
  color: #111111;
}

blockquote cite:after, .btn:hover, .corona-live-update .live-update-title h2 {
  background-color: #111111;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only,
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.sr-only-focusable:active, .sr-only-focusable:focus,
.screen-reader-text:active,
.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  -webkit-clip-path: none;
  clip-path: none;
  color: #161619;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.mt-5,
.my-5 {
  margin-top: 5px !important;
}

.mr-5,
.mx-5 {
  margin-right: 5px !important;
}

.mb-5,
.my-5 {
  margin-bottom: 5px !important;
}

.ml-5,
.mx-5 {
  margin-left: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.mt-10,
.my-10 {
  margin-top: 10px !important;
}

.mr-10,
.mx-10 {
  margin-right: 10px !important;
}

.mb-10,
.my-10 {
  margin-bottom: 10px !important;
}

.ml-10,
.mx-10 {
  margin-left: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.mt-15,
.my-15 {
  margin-top: 15px !important;
}

.mr-15,
.mx-15 {
  margin-right: 15px !important;
}

.mb-15,
.my-15 {
  margin-bottom: 15px !important;
}

.ml-15,
.mx-15 {
  margin-left: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.mt-20,
.my-20 {
  margin-top: 20px !important;
}

.mr-20,
.mx-20 {
  margin-right: 20px !important;
}

.mb-20,
.my-20 {
  margin-bottom: 20px !important;
}

.ml-20,
.mx-20 {
  margin-left: 20px !important;
}

.m-30 {
  margin: 30px !important;
}

.mt-30,
.my-30 {
  margin-top: 30px !important;
}

.mr-30,
.mx-30 {
  margin-right: 30px !important;
}

.mb-30,
.my-30 {
  margin-bottom: 30px !important;
}

.ml-30,
.mx-30 {
  margin-left: 30px !important;
}

.m-40 {
  margin: 40px !important;
}

.mt-40,
.my-40 {
  margin-top: 40px !important;
}

.mr-40,
.mx-40 {
  margin-right: 40px !important;
}

.mb-40,
.my-40 {
  margin-bottom: 40px !important;
}

.ml-40,
.mx-40 {
  margin-left: 40px !important;
}

.m-50 {
  margin: 50px !important;
}

.mt-50,
.my-50 {
  margin-top: 50px !important;
}

.mr-50,
.mx-50 {
  margin-right: 50px !important;
}

.mb-50,
.my-50 {
  margin-bottom: 50px !important;
}

.ml-50,
.mx-50 {
  margin-left: 50px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.pt-5,
.py-5 {
  padding-top: 5px !important;
}

.pr-5,
.px-5 {
  padding-right: 5px !important;
}

.pb-5,
.py-5 {
  padding-bottom: 5px !important;
}

.pl-5,
.px-5 {
  padding-left: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.pt-10,
.py-10 {
  padding-top: 10px !important;
}

.pr-10,
.px-10 {
  padding-right: 10px !important;
}

.pb-10,
.py-10 {
  padding-bottom: 10px !important;
}

.pl-10,
.px-10 {
  padding-left: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-15,
.py-15 {
  padding-top: 15px !important;
}

.pr-15,
.px-15 {
  padding-right: 15px !important;
}

.pb-15,
.py-15 {
  padding-bottom: 15px !important;
}

.pl-15,
.px-15 {
  padding-left: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.pt-20,
.py-20 {
  padding-top: 20px !important;
}

.pr-20,
.px-20 {
  padding-right: 20px !important;
}

.pb-20,
.py-20 {
  padding-bottom: 20px !important;
}

.pl-20,
.px-20 {
  padding-left: 20px !important;
}

.p-30 {
  padding: 30px !important;
}

.pt-30,
.py-30 {
  padding-top: 30px !important;
}

.pr-30,
.px-30 {
  padding-right: 30px !important;
}

.pb-30,
.py-30 {
  padding-bottom: 30px !important;
}

.pl-30,
.px-30 {
  padding-left: 30px !important;
}

.p-40 {
  padding: 40px !important;
}

.pt-40,
.py-40 {
  padding-top: 40px !important;
}

.pr-40,
.px-40 {
  padding-right: 40px !important;
}

.pb-40,
.py-40 {
  padding-bottom: 40px !important;
}

.pl-40,
.px-40 {
  padding-left: 40px !important;
}

.p-50 {
  padding: 50px !important;
}

.pt-50,
.py-50 {
  padding-top: 50px !important;
}

.pr-50,
.px-50 {
  padding-right: 50px !important;
}

.pb-50,
.py-50 {
  padding-bottom: 50px !important;
}

.pl-50,
.px-50 {
  padding-left: 50px !important;
}

.m-n5 {
  margin: -5px !important;
}

.mt-n5,
.my-n5 {
  margin-top: -5px !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -5px !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -5px !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -5px !important;
}

.m-n10 {
  margin: -10px !important;
}

.mt-n10,
.my-n10 {
  margin-top: -10px !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -10px !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -10px !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -10px !important;
}

.m-n15 {
  margin: -15px !important;
}

.mt-n15,
.my-n15 {
  margin-top: -15px !important;
}

.mr-n15,
.mx-n15 {
  margin-right: -15px !important;
}

.mb-n15,
.my-n15 {
  margin-bottom: -15px !important;
}

.ml-n15,
.mx-n15 {
  margin-left: -15px !important;
}

.m-n20 {
  margin: -20px !important;
}

.mt-n20,
.my-n20 {
  margin-top: -20px !important;
}

.mr-n20,
.mx-n20 {
  margin-right: -20px !important;
}

.mb-n20,
.my-n20 {
  margin-bottom: -20px !important;
}

.ml-n20,
.mx-n20 {
  margin-left: -20px !important;
}

.m-n30 {
  margin: -30px !important;
}

.mt-n30,
.my-n30 {
  margin-top: -30px !important;
}

.mr-n30,
.mx-n30 {
  margin-right: -30px !important;
}

.mb-n30,
.my-n30 {
  margin-bottom: -30px !important;
}

.ml-n30,
.mx-n30 {
  margin-left: -30px !important;
}

.m-n40 {
  margin: -40px !important;
}

.mt-n40,
.my-n40 {
  margin-top: -40px !important;
}

.mr-n40,
.mx-n40 {
  margin-right: -40px !important;
}

.mb-n40,
.my-n40 {
  margin-bottom: -40px !important;
}

.ml-n40,
.mx-n40 {
  margin-left: -40px !important;
}

.m-n50 {
  margin: -50px !important;
}

.mt-n50,
.my-n50 {
  margin-top: -50px !important;
}

.mr-n50,
.mx-n50 {
  margin-right: -50px !important;
}

.mb-n50,
.my-n50 {
  margin-bottom: -50px !important;
}

.ml-n50,
.mx-n50 {
  margin-left: -50px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-5 {
    margin: 5px !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 5px !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 5px !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 5px !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 5px !important;
  }
  .m-sm-10 {
    margin: 10px !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10px !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10px !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10px !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10px !important;
  }
  .m-sm-15 {
    margin: 15px !important;
  }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 15px !important;
  }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 15px !important;
  }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 15px !important;
  }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 15px !important;
  }
  .m-sm-20 {
    margin: 20px !important;
  }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 20px !important;
  }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 20px !important;
  }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 20px !important;
  }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 20px !important;
  }
  .m-sm-30 {
    margin: 30px !important;
  }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 30px !important;
  }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 30px !important;
  }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 30px !important;
  }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 30px !important;
  }
  .m-sm-40 {
    margin: 40px !important;
  }
  .mt-sm-40,
  .my-sm-40 {
    margin-top: 40px !important;
  }
  .mr-sm-40,
  .mx-sm-40 {
    margin-right: 40px !important;
  }
  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 40px !important;
  }
  .ml-sm-40,
  .mx-sm-40 {
    margin-left: 40px !important;
  }
  .m-sm-50 {
    margin: 50px !important;
  }
  .mt-sm-50,
  .my-sm-50 {
    margin-top: 50px !important;
  }
  .mr-sm-50,
  .mx-sm-50 {
    margin-right: 50px !important;
  }
  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 50px !important;
  }
  .ml-sm-50,
  .mx-sm-50 {
    margin-left: 50px !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-5 {
    padding: 5px !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 5px !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 5px !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 5px !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 5px !important;
  }
  .p-sm-10 {
    padding: 10px !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10px !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10px !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10px !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10px !important;
  }
  .p-sm-15 {
    padding: 15px !important;
  }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 15px !important;
  }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 15px !important;
  }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 15px !important;
  }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 15px !important;
  }
  .p-sm-20 {
    padding: 20px !important;
  }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 20px !important;
  }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 20px !important;
  }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 20px !important;
  }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 20px !important;
  }
  .p-sm-30 {
    padding: 30px !important;
  }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 30px !important;
  }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 30px !important;
  }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 30px !important;
  }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 30px !important;
  }
  .p-sm-40 {
    padding: 40px !important;
  }
  .pt-sm-40,
  .py-sm-40 {
    padding-top: 40px !important;
  }
  .pr-sm-40,
  .px-sm-40 {
    padding-right: 40px !important;
  }
  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 40px !important;
  }
  .pl-sm-40,
  .px-sm-40 {
    padding-left: 40px !important;
  }
  .p-sm-50 {
    padding: 50px !important;
  }
  .pt-sm-50,
  .py-sm-50 {
    padding-top: 50px !important;
  }
  .pr-sm-50,
  .px-sm-50 {
    padding-right: 50px !important;
  }
  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 50px !important;
  }
  .pl-sm-50,
  .px-sm-50 {
    padding-left: 50px !important;
  }
  .m-sm-n5 {
    margin: -5px !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -5px !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -5px !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -5px !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -5px !important;
  }
  .m-sm-n10 {
    margin: -10px !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -10px !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -10px !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -10px !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -10px !important;
  }
  .m-sm-n15 {
    margin: -15px !important;
  }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -15px !important;
  }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -15px !important;
  }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -15px !important;
  }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -15px !important;
  }
  .m-sm-n20 {
    margin: -20px !important;
  }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -20px !important;
  }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -20px !important;
  }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -20px !important;
  }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -20px !important;
  }
  .m-sm-n30 {
    margin: -30px !important;
  }
  .mt-sm-n30,
  .my-sm-n30 {
    margin-top: -30px !important;
  }
  .mr-sm-n30,
  .mx-sm-n30 {
    margin-right: -30px !important;
  }
  .mb-sm-n30,
  .my-sm-n30 {
    margin-bottom: -30px !important;
  }
  .ml-sm-n30,
  .mx-sm-n30 {
    margin-left: -30px !important;
  }
  .m-sm-n40 {
    margin: -40px !important;
  }
  .mt-sm-n40,
  .my-sm-n40 {
    margin-top: -40px !important;
  }
  .mr-sm-n40,
  .mx-sm-n40 {
    margin-right: -40px !important;
  }
  .mb-sm-n40,
  .my-sm-n40 {
    margin-bottom: -40px !important;
  }
  .ml-sm-n40,
  .mx-sm-n40 {
    margin-left: -40px !important;
  }
  .m-sm-n50 {
    margin: -50px !important;
  }
  .mt-sm-n50,
  .my-sm-n50 {
    margin-top: -50px !important;
  }
  .mr-sm-n50,
  .mx-sm-n50 {
    margin-right: -50px !important;
  }
  .mb-sm-n50,
  .my-sm-n50 {
    margin-bottom: -50px !important;
  }
  .ml-sm-n50,
  .mx-sm-n50 {
    margin-left: -50px !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-5 {
    margin: 5px !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 5px !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 5px !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 5px !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 5px !important;
  }
  .m-md-10 {
    margin: 10px !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10px !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10px !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10px !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10px !important;
  }
  .m-md-15 {
    margin: 15px !important;
  }
  .mt-md-15,
  .my-md-15 {
    margin-top: 15px !important;
  }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 15px !important;
  }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 15px !important;
  }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 15px !important;
  }
  .m-md-20 {
    margin: 20px !important;
  }
  .mt-md-20,
  .my-md-20 {
    margin-top: 20px !important;
  }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 20px !important;
  }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 20px !important;
  }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 20px !important;
  }
  .m-md-30 {
    margin: 30px !important;
  }
  .mt-md-30,
  .my-md-30 {
    margin-top: 30px !important;
  }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 30px !important;
  }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 30px !important;
  }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 30px !important;
  }
  .m-md-40 {
    margin: 40px !important;
  }
  .mt-md-40,
  .my-md-40 {
    margin-top: 40px !important;
  }
  .mr-md-40,
  .mx-md-40 {
    margin-right: 40px !important;
  }
  .mb-md-40,
  .my-md-40 {
    margin-bottom: 40px !important;
  }
  .ml-md-40,
  .mx-md-40 {
    margin-left: 40px !important;
  }
  .m-md-50 {
    margin: 50px !important;
  }
  .mt-md-50,
  .my-md-50 {
    margin-top: 50px !important;
  }
  .mr-md-50,
  .mx-md-50 {
    margin-right: 50px !important;
  }
  .mb-md-50,
  .my-md-50 {
    margin-bottom: 50px !important;
  }
  .ml-md-50,
  .mx-md-50 {
    margin-left: 50px !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-5 {
    padding: 5px !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 5px !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 5px !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 5px !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 5px !important;
  }
  .p-md-10 {
    padding: 10px !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10px !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10px !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10px !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10px !important;
  }
  .p-md-15 {
    padding: 15px !important;
  }
  .pt-md-15,
  .py-md-15 {
    padding-top: 15px !important;
  }
  .pr-md-15,
  .px-md-15 {
    padding-right: 15px !important;
  }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 15px !important;
  }
  .pl-md-15,
  .px-md-15 {
    padding-left: 15px !important;
  }
  .p-md-20 {
    padding: 20px !important;
  }
  .pt-md-20,
  .py-md-20 {
    padding-top: 20px !important;
  }
  .pr-md-20,
  .px-md-20 {
    padding-right: 20px !important;
  }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 20px !important;
  }
  .pl-md-20,
  .px-md-20 {
    padding-left: 20px !important;
  }
  .p-md-30 {
    padding: 30px !important;
  }
  .pt-md-30,
  .py-md-30 {
    padding-top: 30px !important;
  }
  .pr-md-30,
  .px-md-30 {
    padding-right: 30px !important;
  }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 30px !important;
  }
  .pl-md-30,
  .px-md-30 {
    padding-left: 30px !important;
  }
  .p-md-40 {
    padding: 40px !important;
  }
  .pt-md-40,
  .py-md-40 {
    padding-top: 40px !important;
  }
  .pr-md-40,
  .px-md-40 {
    padding-right: 40px !important;
  }
  .pb-md-40,
  .py-md-40 {
    padding-bottom: 40px !important;
  }
  .pl-md-40,
  .px-md-40 {
    padding-left: 40px !important;
  }
  .p-md-50 {
    padding: 50px !important;
  }
  .pt-md-50,
  .py-md-50 {
    padding-top: 50px !important;
  }
  .pr-md-50,
  .px-md-50 {
    padding-right: 50px !important;
  }
  .pb-md-50,
  .py-md-50 {
    padding-bottom: 50px !important;
  }
  .pl-md-50,
  .px-md-50 {
    padding-left: 50px !important;
  }
  .m-md-n5 {
    margin: -5px !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -5px !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -5px !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -5px !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -5px !important;
  }
  .m-md-n10 {
    margin: -10px !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -10px !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -10px !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -10px !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -10px !important;
  }
  .m-md-n15 {
    margin: -15px !important;
  }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -15px !important;
  }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -15px !important;
  }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -15px !important;
  }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -15px !important;
  }
  .m-md-n20 {
    margin: -20px !important;
  }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -20px !important;
  }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -20px !important;
  }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -20px !important;
  }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -20px !important;
  }
  .m-md-n30 {
    margin: -30px !important;
  }
  .mt-md-n30,
  .my-md-n30 {
    margin-top: -30px !important;
  }
  .mr-md-n30,
  .mx-md-n30 {
    margin-right: -30px !important;
  }
  .mb-md-n30,
  .my-md-n30 {
    margin-bottom: -30px !important;
  }
  .ml-md-n30,
  .mx-md-n30 {
    margin-left: -30px !important;
  }
  .m-md-n40 {
    margin: -40px !important;
  }
  .mt-md-n40,
  .my-md-n40 {
    margin-top: -40px !important;
  }
  .mr-md-n40,
  .mx-md-n40 {
    margin-right: -40px !important;
  }
  .mb-md-n40,
  .my-md-n40 {
    margin-bottom: -40px !important;
  }
  .ml-md-n40,
  .mx-md-n40 {
    margin-left: -40px !important;
  }
  .m-md-n50 {
    margin: -50px !important;
  }
  .mt-md-n50,
  .my-md-n50 {
    margin-top: -50px !important;
  }
  .mr-md-n50,
  .mx-md-n50 {
    margin-right: -50px !important;
  }
  .mb-md-n50,
  .my-md-n50 {
    margin-bottom: -50px !important;
  }
  .ml-md-n50,
  .mx-md-n50 {
    margin-left: -50px !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-5 {
    margin: 5px !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 5px !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 5px !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5px !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 5px !important;
  }
  .m-lg-10 {
    margin: 10px !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10px !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10px !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10px !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10px !important;
  }
  .m-lg-15 {
    margin: 15px !important;
  }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 15px !important;
  }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 15px !important;
  }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 15px !important;
  }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 15px !important;
  }
  .m-lg-20 {
    margin: 20px !important;
  }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 20px !important;
  }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 20px !important;
  }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 20px !important;
  }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 20px !important;
  }
  .m-lg-30 {
    margin: 30px !important;
  }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 30px !important;
  }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 30px !important;
  }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 30px !important;
  }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 30px !important;
  }
  .m-lg-40 {
    margin: 40px !important;
  }
  .mt-lg-40,
  .my-lg-40 {
    margin-top: 40px !important;
  }
  .mr-lg-40,
  .mx-lg-40 {
    margin-right: 40px !important;
  }
  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 40px !important;
  }
  .ml-lg-40,
  .mx-lg-40 {
    margin-left: 40px !important;
  }
  .m-lg-50 {
    margin: 50px !important;
  }
  .mt-lg-50,
  .my-lg-50 {
    margin-top: 50px !important;
  }
  .mr-lg-50,
  .mx-lg-50 {
    margin-right: 50px !important;
  }
  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 50px !important;
  }
  .ml-lg-50,
  .mx-lg-50 {
    margin-left: 50px !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-5 {
    padding: 5px !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 5px !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 5px !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 5px !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 5px !important;
  }
  .p-lg-10 {
    padding: 10px !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10px !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10px !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10px !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10px !important;
  }
  .p-lg-15 {
    padding: 15px !important;
  }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 15px !important;
  }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 15px !important;
  }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 15px !important;
  }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 15px !important;
  }
  .p-lg-20 {
    padding: 20px !important;
  }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 20px !important;
  }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 20px !important;
  }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 20px !important;
  }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 20px !important;
  }
  .p-lg-30 {
    padding: 30px !important;
  }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 30px !important;
  }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 30px !important;
  }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 30px !important;
  }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 30px !important;
  }
  .p-lg-40 {
    padding: 40px !important;
  }
  .pt-lg-40,
  .py-lg-40 {
    padding-top: 40px !important;
  }
  .pr-lg-40,
  .px-lg-40 {
    padding-right: 40px !important;
  }
  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 40px !important;
  }
  .pl-lg-40,
  .px-lg-40 {
    padding-left: 40px !important;
  }
  .p-lg-50 {
    padding: 50px !important;
  }
  .pt-lg-50,
  .py-lg-50 {
    padding-top: 50px !important;
  }
  .pr-lg-50,
  .px-lg-50 {
    padding-right: 50px !important;
  }
  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 50px !important;
  }
  .pl-lg-50,
  .px-lg-50 {
    padding-left: 50px !important;
  }
  .m-lg-n5 {
    margin: -5px !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -5px !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -5px !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -5px !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -5px !important;
  }
  .m-lg-n10 {
    margin: -10px !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -10px !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -10px !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -10px !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -10px !important;
  }
  .m-lg-n15 {
    margin: -15px !important;
  }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -15px !important;
  }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -15px !important;
  }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -15px !important;
  }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -15px !important;
  }
  .m-lg-n20 {
    margin: -20px !important;
  }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -20px !important;
  }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -20px !important;
  }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -20px !important;
  }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -20px !important;
  }
  .m-lg-n30 {
    margin: -30px !important;
  }
  .mt-lg-n30,
  .my-lg-n30 {
    margin-top: -30px !important;
  }
  .mr-lg-n30,
  .mx-lg-n30 {
    margin-right: -30px !important;
  }
  .mb-lg-n30,
  .my-lg-n30 {
    margin-bottom: -30px !important;
  }
  .ml-lg-n30,
  .mx-lg-n30 {
    margin-left: -30px !important;
  }
  .m-lg-n40 {
    margin: -40px !important;
  }
  .mt-lg-n40,
  .my-lg-n40 {
    margin-top: -40px !important;
  }
  .mr-lg-n40,
  .mx-lg-n40 {
    margin-right: -40px !important;
  }
  .mb-lg-n40,
  .my-lg-n40 {
    margin-bottom: -40px !important;
  }
  .ml-lg-n40,
  .mx-lg-n40 {
    margin-left: -40px !important;
  }
  .m-lg-n50 {
    margin: -50px !important;
  }
  .mt-lg-n50,
  .my-lg-n50 {
    margin-top: -50px !important;
  }
  .mr-lg-n50,
  .mx-lg-n50 {
    margin-right: -50px !important;
  }
  .mb-lg-n50,
  .my-lg-n50 {
    margin-bottom: -50px !important;
  }
  .ml-lg-n50,
  .mx-lg-n50 {
    margin-left: -50px !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-5 {
    margin: 5px !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 5px !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 5px !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 5px !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 5px !important;
  }
  .m-xl-10 {
    margin: 10px !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10px !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10px !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10px !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10px !important;
  }
  .m-xl-15 {
    margin: 15px !important;
  }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 15px !important;
  }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 15px !important;
  }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 15px !important;
  }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 15px !important;
  }
  .m-xl-20 {
    margin: 20px !important;
  }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 20px !important;
  }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 20px !important;
  }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 20px !important;
  }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 20px !important;
  }
  .m-xl-30 {
    margin: 30px !important;
  }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 30px !important;
  }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 30px !important;
  }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 30px !important;
  }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 30px !important;
  }
  .m-xl-40 {
    margin: 40px !important;
  }
  .mt-xl-40,
  .my-xl-40 {
    margin-top: 40px !important;
  }
  .mr-xl-40,
  .mx-xl-40 {
    margin-right: 40px !important;
  }
  .mb-xl-40,
  .my-xl-40 {
    margin-bottom: 40px !important;
  }
  .ml-xl-40,
  .mx-xl-40 {
    margin-left: 40px !important;
  }
  .m-xl-50 {
    margin: 50px !important;
  }
  .mt-xl-50,
  .my-xl-50 {
    margin-top: 50px !important;
  }
  .mr-xl-50,
  .mx-xl-50 {
    margin-right: 50px !important;
  }
  .mb-xl-50,
  .my-xl-50 {
    margin-bottom: 50px !important;
  }
  .ml-xl-50,
  .mx-xl-50 {
    margin-left: 50px !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-5 {
    padding: 5px !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 5px !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 5px !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 5px !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 5px !important;
  }
  .p-xl-10 {
    padding: 10px !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10px !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10px !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10px !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10px !important;
  }
  .p-xl-15 {
    padding: 15px !important;
  }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 15px !important;
  }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 15px !important;
  }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 15px !important;
  }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 15px !important;
  }
  .p-xl-20 {
    padding: 20px !important;
  }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 20px !important;
  }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 20px !important;
  }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 20px !important;
  }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 20px !important;
  }
  .p-xl-30 {
    padding: 30px !important;
  }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 30px !important;
  }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 30px !important;
  }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 30px !important;
  }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 30px !important;
  }
  .p-xl-40 {
    padding: 40px !important;
  }
  .pt-xl-40,
  .py-xl-40 {
    padding-top: 40px !important;
  }
  .pr-xl-40,
  .px-xl-40 {
    padding-right: 40px !important;
  }
  .pb-xl-40,
  .py-xl-40 {
    padding-bottom: 40px !important;
  }
  .pl-xl-40,
  .px-xl-40 {
    padding-left: 40px !important;
  }
  .p-xl-50 {
    padding: 50px !important;
  }
  .pt-xl-50,
  .py-xl-50 {
    padding-top: 50px !important;
  }
  .pr-xl-50,
  .px-xl-50 {
    padding-right: 50px !important;
  }
  .pb-xl-50,
  .py-xl-50 {
    padding-bottom: 50px !important;
  }
  .pl-xl-50,
  .px-xl-50 {
    padding-left: 50px !important;
  }
  .m-xl-n5 {
    margin: -5px !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -5px !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -5px !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -5px !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -5px !important;
  }
  .m-xl-n10 {
    margin: -10px !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -10px !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -10px !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -10px !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -10px !important;
  }
  .m-xl-n15 {
    margin: -15px !important;
  }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -15px !important;
  }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -15px !important;
  }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -15px !important;
  }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -15px !important;
  }
  .m-xl-n20 {
    margin: -20px !important;
  }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -20px !important;
  }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -20px !important;
  }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -20px !important;
  }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -20px !important;
  }
  .m-xl-n30 {
    margin: -30px !important;
  }
  .mt-xl-n30,
  .my-xl-n30 {
    margin-top: -30px !important;
  }
  .mr-xl-n30,
  .mx-xl-n30 {
    margin-right: -30px !important;
  }
  .mb-xl-n30,
  .my-xl-n30 {
    margin-bottom: -30px !important;
  }
  .ml-xl-n30,
  .mx-xl-n30 {
    margin-left: -30px !important;
  }
  .m-xl-n40 {
    margin: -40px !important;
  }
  .mt-xl-n40,
  .my-xl-n40 {
    margin-top: -40px !important;
  }
  .mr-xl-n40,
  .mx-xl-n40 {
    margin-right: -40px !important;
  }
  .mb-xl-n40,
  .my-xl-n40 {
    margin-bottom: -40px !important;
  }
  .ml-xl-n40,
  .mx-xl-n40 {
    margin-left: -40px !important;
  }
  .m-xl-n50 {
    margin: -50px !important;
  }
  .mt-xl-n50,
  .my-xl-n50 {
    margin-top: -50px !important;
  }
  .mr-xl-n50,
  .mx-xl-n50 {
    margin-right: -50px !important;
  }
  .mb-xl-n50,
  .my-xl-n50 {
    margin-bottom: -50px !important;
  }
  .ml-xl-n50,
  .mx-xl-n50 {
    margin-left: -50px !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-white {
  color: #ffffff !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

/* ************************
   02.1: Common Classes
   ********************* */
.pt-120 {
  padding-top: 120px;
}

@media only screen and (max-width: 767px) {
  .pt-120 {
    padding-top: 80px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}

@media only screen and (max-width: 767px) {
  .pb-120 {
    padding-bottom: 80px;
  }
}

.pb-90 {
  padding-bottom: 90px;
}

@media only screen and (max-width: 767px) {
  .pb-90 {
    padding-bottom: 50px;
  }
}

.mb-75 {
  margin-bottom: 75px;
}

a, .btn, .main-menu #menu-button span,
.main-menu #menu-button span:after,
.main-menu #menu-button span:before, .main-menu .nav li ul li:hover > ul, .single-country .flag-wrapper .flag-icon:after, .single-country .flag-wrapper .country-name, .single-country .country-info {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6, .btn-icon, .theme-input-lable, .main-menu .nav, .section-title span, .card .card-body span, .single-country .flag-wrapper .country-name, .coronaDataTableWrapper .dataTables_length,
.coronaDataTableWrapper .dataTables_filter label, table.coronaDataTable thead th, .pagination li a {
  font-family: "Poppins", sans-serif;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.bg-light {
  background-color: #f2f4f9;
}

.bg-white {
  background-color: #ffffff;
}

.bg-warning {
  background-color: #ffc825;
}

.bg-denger {
  background-color: #f70000;
}

.bg-success {
  background-color: #19c858;
}

.bg-info {
  background-color: #d1ecf1;
}

.bg-cover {
  background-size: cover;
  background-position: center;
  background-color: #06133e;
}

.pattern-bg-wrapper {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.pattern-bg {
  position: absolute;
  left: -35%;
  bottom: 120px;
  z-index: -1;
}

.pattern-bg.right {
  left: auto;
  right: -35%;
}

.radius-10 {
  border-radius: 10px;
}

/* List Style */
.list-unstyled {
  padding: 0;
  margin: 0;
  list-style: none;
}

.tips-list li .icon {
  min-width: 15px;
  margin-right: 10px;
}

.tips-list li:not(:last-child) {
  margin-bottom: 15px;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.info-text p {
  margin-bottom: 20px;
}

.border-top {
  border-top: 1px solid rgba(112, 112, 112, 0.15);
}

.border-bottom, .comments-area .single-comment {
  border-bottom: 1px solid rgba(112, 112, 112, 0.15);
}

.video-area .btn-play {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

#coronaTestOnline,
#coronaUpdateMap {
  height: 600px;
  width: 100%;
  display: block;
}

.search-form .theme-input-wrapper .theme-input-style, .search-form .theme-input-wrapper .coronaDataTableWrapper .dataTables_filter input, .coronaDataTableWrapper .dataTables_filter .search-form .theme-input-wrapper input {
  border: 1px solid #f0f0f0;
  border-radius: 5px 0 0 5px;
  height: 45px;
  border-right: 0;
}

.search-form .theme-input-wrapper button.btn {
  height: 45px;
  padding: 0 20px;
}

blockquote {
  max-width: 570px;
  margin: 0 auto 30px;
}

blockquote p {
  font-size: 18px;
  line-height: 1.67;
  font-style: italic;
  text-decoration: underline;
  margin-bottom: 15px !important;
}

blockquote cite {
  font-style: normal;
  font-size: 16px;
  position: relative;
  padding-left: 20px;
  line-height: 1;
}

blockquote cite:after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 3px;
  width: 10px;
}

.preloader {
  top: 0;
  left: 0;
  z-index: 99999;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader img {
  animation: spinner 1.2s infinite linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ************************
   02.2: Buttons
   ********************* */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  padding: 21.5px 32px;
  line-height: 1;
  letter-spacing: 1.2px;
  white-space: nowrap;
  text-transform: uppercase;
  border-radius: 50px;
}

.btn:hover {
  color: #ffffff;
}

.btn.btn-borderd {
  background-color: transparent;
  border: 1px solid #ffffff;
}

.btn-play {
  height: 74px;
  width: 74px;
  border-radius: 50%;
  background-color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-play:after {
  border: 8px solid rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  content: '';
  display: block;
  position: absolute;
  left: -8px;
  top: -8px;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  z-index: -1;
}

.btn-play:hover:after {
  animation: ripple 1s linear infinite;
}

.btn-icon {
  font-size: 14px;
  font-weight: 700;
}

.btn-icon .icon {
  height: 22px;
  width: 22px;
  border-radius: 5px;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    border-color: transparent;
  }
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    border-color: transparent;
  }
}

/* ************************
   02.3: Input
   ********************* */
.theme-input-style, .coronaDataTableWrapper .dataTables_filter input {
  display: block;
  width: 100%;
  height: 56px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 0 15px;
  background-color: transparent;
}

textarea.theme-input-style {
  padding-top: 15px;
  height: 200px;
}

.theme-input-lable {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.theme-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  overflow: hidden;
}

.theme-input-wrapper .theme-input-style, .theme-input-wrapper .coronaDataTableWrapper .dataTables_filter input, .coronaDataTableWrapper .dataTables_filter .theme-input-wrapper input {
  flex: 1 1 0%;
  min-width: 0;
  border: none;
  height: 60px;
  background-color: #ffffff;
  border-radius: 0;
}

.theme-input-wrapper button {
  height: 60px;
  border-radius: 0 5px 5px 0;
}

/* ************************
   02.4: Menu
   ********************* */
.main-menu #menu-button {
  cursor: pointer;
  height: 25px;
  width: 25px;
  display: none;
}

@media only screen and (max-width: 991px) {
  .main-menu #menu-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.main-menu #menu-button span {
  position: relative;
}

.main-menu #menu-button span,
.main-menu #menu-button span:after,
.main-menu #menu-button span:before {
  display: block;
  width: 25px;
  height: 2px;
  background-color: #ffffff;
}

.main-menu #menu-button span:after,
.main-menu #menu-button span:before {
  position: absolute;
  content: "";
}

.main-menu #menu-button span:after {
  top: 7px;
}

.main-menu #menu-button span:before {
  top: -7px;
}

.main-menu #menu-button.menu-opened span {
  background-color: transparent !important;
}

.main-menu #menu-button.menu-opened span:before {
  transform: translateY(7px) rotate(45deg);
}

.main-menu #menu-button.menu-opened span:after {
  transform: translateY(-7px) rotate(-45deg);
}

.main-menu .nav {
  font-size: 14px;
}

.main-menu .nav > li {
  letter-spacing: 1.2px;
  padding: 0 15px;
}

.main-menu .nav > li > a {
  text-transform: uppercase;
  font-weight: 500;
  line-height: 85px;
  color: #ffffff;
}

.main-menu .nav > li:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
  transform: translate3d(0, 0, 0);
}

.main-menu .nav li {
  position: relative;
  font-weight: 400;
}

.main-menu .nav li a {
  display: block;
}

.main-menu .nav li ul {
  position: absolute;
  left: 0;
  top: -2000px;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  width: 210px;
  padding: 15px 0;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  transform: translate3d(0, -5px, 0);
  transition: transform 0.3s ease-out;
  z-index: 9;
}

.main-menu .nav li ul li {
  display: block;
  width: 100%;
  line-height: 1;
}

.main-menu .nav li ul li:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 0;
  left: 100%;
}

.main-menu .nav li ul li a {
  padding: 10px 30px;
}

.main-menu .nav li ul li ul {
  top: 0;
  left: calc(100% + 20px);
}

@media only screen and (max-width: 991px) {
  .main-menu .nav {
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    right: 50%;
    width: calc(100% - 30px);
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    max-height: 350px;
    overflow-y: auto;
    padding: 15px 0;
    max-width: 800px;
    transform: translateX(-50%);
    z-index: 99999;
    margin-right: 0;
  }
  .main-menu .nav > li {
    padding: 0;
  }
  .main-menu .nav > li > a {
    color: #111111;
    line-height: 1;
    padding: 15px 20px;
  }
  .main-menu .nav .submenu-button {
    display: block;
    position: absolute;
    z-index: 9;
    top: 0;
    right: 15px;
    width: 40px;
    height: inherit;
    line-height: 40px;
    text-align: center;
    color: initial;
  }
  .main-menu .nav .submenu-button:after {
    content: ">";
  }
  .main-menu .nav .submenu-button.submenu-opened:after {
    content: "˅";
  }
  .main-menu .nav li {
    display: block;
    width: 100%;
    overflow: hidden;
  }
  .main-menu .nav li ul {
    position: relative;
    left: 0;
    right: auto;
    top: 0;
    width: 100%;
    display: none;
    padding: 0;
    opacity: 1;
    visibility: visible;
    text-align: left;
    z-index: 99999;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .main-menu .nav li ul ul {
    position: relative;
    margin-left: 30px;
    left: 0 !important;
    opacity: 1;
    visibility: visible;
  }
}

/* ************************
   02.5: Section Title
   ********************* */
.section-title span {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 3.2px;
  text-transform: uppercase;
  margin-bottom: 20px;
  line-height: 1;
  display: block;
  margin-bottom: 22px;
}

.section-title h2 {
  font-weight: 500;
  font-size: 42px;
}

/* ************************
   02.6: Features
   ********************* */
.single-symptoms:not(:last-child) {
  padding-right: 35px;
  margin-right: 35px;
  border-right: 1px solid rgba(112, 112, 112, 0.15);
}

@media only screen and (max-width: 575px) {
  .single-symptoms:not(:last-child) {
    border-right: 0;
    border-bottom: 1px solid rgba(112, 112, 112, 0.15);
    padding-right: 0;
    margin-right: 0;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}

.single-symptoms .icon {
  margin-right: 12px;
}

.single-symptoms p {
  max-width: 70px;
  line-height: 20px;
}

@media only screen and (max-width: 575px) {
  .single-symptoms p {
    max-width: 100%;
  }
}

.single-prevention .content p {
  font-size: 21px;
  font-weight: 800;
  line-height: 26px;
}

@media only screen and (max-width: 991px) {
  .single-prevention .content p br {
    display: none;
  }
}

/* ************************
   02.7: Card
   ********************* */
.card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.02);
}

.card .card-body {
  padding: 35px 30px;
}

.card .card-body span {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 3.2px;
}

.card .card-body h3 {
  font-weight: 500;
  margin: 10px 0 18px;
}

.card-wrapper .card:not(:last-child) {
  margin-bottom: 30px;
}

/* ************************
   02.8: Widget
   ********************* */
.widget:not(:last-child) {
  margin-bottom: 50px;
}

.widget .widget-title {
  margin-bottom: 30px;
}

.widget ul li:not(:last-child) {
  margin-bottom: 9px;
}

.widget ul li a {
  color: inherit;
}

.widget.widget-about p {
  max-width: 230px;
}

.widget.widget-social-icon li:not(:last-child) {
  margin-right: 5px;
  margin-bottom: 0;
}

.widget.widget-social-icon li a {
  width: 30px;
  height: 30px;
  background-color: rgba(42, 81, 101, 0.5);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.widget .text-white li a:hover {
  color: #ffffff;
  opacity: 0.8;
}

/* ************************
   03.6: Page Title
   ********************* */
/* ************************
   02.9: Page Title
   ********************* */
.page-title-wrap {
  padding: 185px 0 100px;
}

.page-title h2 {
  font-size: 36px;
  font-weight: 700;
}

.page-title ul li a {
  color: inherit;
}

.page-title ul li a:hover {
  opacity: 0.8;
}

.page-title ul li:not(:last-child) {
  position: relative;
}

.page-title ul li:not(:last-child):after {
  content: ">";
  margin: 10px;
}

/* ************************
   03.1: Header
   ********************* */
.header {
  position: relative;
}

.header .header-fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: 20px 0;
  transition: .3s linear;
}

.header .header-fixed.sticky {
  background-color: #06133E;
  box-shadow: 0 0 20px rgba(6, 19, 62, 0.15);
}

@media only screen and (min-width: 992px) {
  .header .header-fixed.sticky {
    padding: 0;
  }
}

.header .header-btn {
  padding: 16.5px 29px;
}

@media only screen and (max-width: 575px) {
  .header .header-btn {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    padding: 0;
  }
}

/* ************************
   03.2: Banner
   ********************* */
.banner {
  min-height: 960px;
  background-size: cover;
  background-position: center;
}

@media only screen and (min-width: 992px) {
  .banner .banner-content {
    padding: 287px 0 413px;
  }
}

.banner .banner-content h1 {
  font-size: 52px;
  line-height: calc(65 / 52);
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 38px;
}

@media only screen and (max-width: 767px) {
  .banner .banner-content h1 {
    font-size: 42px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .banner .banner-content h1 {
    font-size: 30px;
  }
}

.banner .banner-content p {
  color: #ffffff;
  font-size: 22px;
  font-weight: 300;
  line-height: 33px;
  max-width: 970px;
}

@media only screen and (max-width: 767px) {
  .banner .banner-content p {
    font-size: 18px;
    margin-bottom: 30px;
  }
}

.banner .banner-btn-group .btn {
  margin-top: 15px;
  padding: 21.5px 32px;
}

.banner .banner-btn-group .btn:not(:last-child) {
  margin-right: 11px;
}

.banner.text-center .banner-content p {
  padding-left: 0;
  padding-right: 0;
}

.banner.text-right .banner-content p {
  padding-left: 0;
}

/* ************************
   03.3: Coron Update
   ********************* */
.corona-live-update {
  background-position: center;
  background-color: #ffffff;
  border-radius: 10px;
}

@media only screen and (min-width: 992px) {
  .corona-live-update > .row > .col-lg:first-child {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .corona-live-update > .row > .col-lg:last-child {
    flex: 0 0 80%;
    max-width: 80%;
  }
}

.corona-live-update .live-update-title {
  min-height: 200px;
}

.corona-live-update .live-update-title h2 {
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  border-radius: 0 50px 50px 0;
  color: #ffffff;
  margin-bottom: 5px;
}

.corona-live-update .live-update-title h2 span:first-child {
  font-size: 24px;
  font-weight: 600;
  background-color: #ffffff;
  border-radius: 0 50px 50px 0;
  padding-right: 5px;
}

.corona-live-update .live-update-title h2 span:last-child {
  padding: 0 10px 0 5px;
}

.corona-live-update .live-update-title > span {
  display: block;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4.5px;
}

.corona-live-update .cu_info {
  min-height: 200px;
}

.corona-live-update .cu_info h2 {
  font-size: 40px;
  font-weight: 600;
}

.corona-live-update .cu_info span {
  font-size: 21px;
}

.corona-live-update .cu_info.cu_confirmed {
  background-color: rgba(247, 139, 0, 0.1);
}

.corona-live-update .cu_info.cu_confirmed h2 {
  color: #F78B00;
}

.corona-live-update .cu_info.cu_recovered {
  background-color: rgba(54, 201, 123, 0.1);
}

.corona-live-update .cu_info.cu_recovered h2 {
  color: #36C97B;
}

.corona-live-update .cu_info.cu_deaths {
  background-color: rgba(204, 12, 27, 0.1);
}

.corona-live-update .cu_info.cu_deaths h2 {
  color: #CC0C1B;
}

.corona-live-update .cu_info.cu_countries {
  background-color: rgba(23, 56, 213, 0.1);
}

.corona-update-group {
  overflow: hidden;
  margin-top: 50px;
}

@media only screen and (min-width: 992px) {
  .corona-update-group {
    margin-top: -170px;
  }
}

.corona-update-group > .row {
  margin-right: -5px;
  margin-bottom: -4px;
}

.corona-update-group > .row > .cu_info {
  min-height: 152px;
  border-width: 0 4px 4px 0;
  border-style: solid;
  border-color: #fff;
}

.corona-update-group > .row > .cu_info span {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 10px;
}

.corona-update-group > .row > .cu_info h2 {
  font-family: "Nunito", sans-serif;
  font-size: 36px;
  font-weight: 800;
}

.single-country {
  position: relative;
  cursor: pointer;
}

.single-country .flag-wrapper {
  position: relative;
  z-index: 1;
  width: 60px;
  height: 60px;
}

.single-country .flag-wrapper:after {
  border: 10px solid rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  content: '';
  display: block;
  position: absolute;
  left: -10px;
  top: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  z-index: -1;
  transition: .3s linear;
}

.single-country .flag-wrapper .flag-icon {
  height: 60px;
  width: 60px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}

.single-country .flag-wrapper .flag-icon:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  content: '';
}

.single-country .flag-wrapper .flag-icon img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.single-country .flag-wrapper .country-name {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
}

.single-country .country-info {
  border-radius: 10px;
  overflow: hidden;
}

.single-country .country-info li {
  padding: 7px 0;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
}

@media only screen and (min-width: 1200px) {
  .single-country .country-name,
  .single-country .country-info,
  .single-country .flag-wrapper .flag-icon:after {
    opacity: 0;
    visibility: hidden;
  }
  .single-country .country-info {
    display: flex;
    position: absolute;
    left: 50%;
    bottom: calc(100% + 30px);
    transform: translateX(-50%);
    border-radius: 50px;
    z-index: 9;
    width: 340px;
    height: 56px;
    backface-visibility: hidden;
  }
  .single-country .country-info li {
    width: 33.33%;
    white-space: nowrap;
    text-align: center;
  }
  .single-country .country-info li:first-child {
    border-radius: 50px 0 0 50px;
  }
  .single-country .country-info li:last-child {
    border-radius: 0 50px 50px 0;
  }
  .single-country:hover .country-name,
  .single-country:hover .country-info,
  .single-country:hover .flag-wrapper .flag-icon:after {
    opacity: 1;
    visibility: visible;
  }
  .single-country:hover .flag-wrapper:after {
    animation: ripple 1s linear infinite;
  }
  .single-country:hover .country-info {
    bottom: calc(100% + 20px);
  }
}

@media only screen and (max-width: 1350px) {
  .corona-country-update .col-xl:first-child .country-info {
    left: 0;
    transform: none;
  }
}

.coronaDataTableWrapper {
  padding: 45px 20px;
  box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.05);
}

.coronaDataTableWrapper .dataTables_length,
.coronaDataTableWrapper .dataTables_filter label {
  font-size: 16px;
  font-weight: 600;
}

.coronaDataTableWrapper .dataTables_length select {
  border: 1px solid #E1E5EA;
  border-radius: 5px;
  padding: 5px;
}

.coronaDataTableWrapper .dataTables_filter label {
  display: flex;
  align-items: center;
}

.coronaDataTableWrapper .dataTables_filter input {
  height: 40px;
  border-color: #E1E5EA;
}

.coronaDataTableWrapper .dataTables_length,
.coronaDataTableWrapper .dataTables_filter {
  margin-bottom: 20px;
}

.coronaDataTableWrapper .dataTables_paginate .paginate_button {
  font-size: 15px;
  background: rgba(0, 0, 0, 0.02);
  border: none;
  border-radius: 2px;
}

.coronaDataTableWrapper .dataTables_paginate .paginate_button:not(.disabled).current, .coronaDataTableWrapper .dataTables_paginate .paginate_button:not(.disabled):hover {
  color: #ffffff !important;
  background: #CC0C1B !important;
  border: none !important;
}

.coronaDataTableWrapper .dataTables_paginate .paginate_button.disabled {
  opacity: .5;
}

table.coronaDataTable {
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  margin-bottom: 20px;
}

table.coronaDataTable thead th {
  text-transform: uppercase;
  padding: 20px;
}

table.coronaDataTable thead th, table.coronaDataTable thead td, table.coronaDataTable tbody th, table.coronaDataTable tbody td {
  border: 1px solid #F0F0F0;
  padding: 15px;
  text-align: center;
}

table.coronaDataTable .flag-img {
  width: 30px;
  height: 30px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}

table.coronaDataTable .flag-img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ************************
   03.4: Blog
   ********************* */
.single-post {
  position: relative;
  margin-bottom: 50px;
}

.single-post .post-thumbnail {
  margin-bottom: 24px;
  display: block;
}

.single-post .post-content .posted-on a {
  font-size: 14px;
}

.single-post .post-content .post-title {
  line-height: 1.25;
  margin-bottom: 16px;
}

.single-post .post-content .post-summary {
  margin-bottom: 21px;
}

.post-details .entry-header .entry-thumbnail {
  margin-bottom: 45px;
}

.post-details .entry-header .entry-title {
  font-size: 36px;
  line-height: 1.28;
  margin-bottom: 15px;
}

.post-details .entry-header .entry-meta {
  margin-bottom: 30px;
}

.post-details .entry-header .entry-meta li:not(:last-child) {
  margin-right: 50px;
}

.post-details .entry-header .entry-meta li span {
  margin-right: 7px;
}

.post-details .entry-tags ul li:first-child,
.post-details .entry-categories ul li:first-child {
  margin-right: 7px;
}

.post-details .entry-tags ul li a:not(:last-child):after,
.post-details .entry-categories ul li a:not(:last-child):after {
  content: ",";
}

.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
  line-height: 1.5;
  margin-bottom: 23px;
}

.entry-content p {
  margin-bottom: 30px;
}

.entry-content > *:last-child {
  margin-bottom: 0;
}

/* Post Author Info */
.post-author {
  padding: 40px 0;
}

@media only screen and (max-width: 575px) {
  .post-author {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.post-author .author-thumb {
  margin-right: 30px;
}

@media only screen and (max-width: 575px) {
  .post-author .author-thumb {
    margin-bottom: 30px;
  }
}

.post-author .author-info h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

.post-author {
  padding: 40px 0;
}

@media only screen and (max-width: 575px) {
  .post-author {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.post-author .author-thumb {
  margin-right: 30px;
}

@media only screen and (max-width: 575px) {
  .post-author .author-thumb {
    margin-bottom: 30px;
  }
}

.post-author .author-info h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

/* Post Comments */
.comments-area .comments-title {
  margin-bottom: 25px;
}

.comments-area .single-comment {
  padding-bottom: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .comments-area .single-comment {
    flex-direction: column;
  }
}

.comments-area .single-comment .comment-author-image {
  min-width: 65px;
  margin-right: 30px;
  border-radius: 50%;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {
  .comments-area .single-comment .comment-author-image {
    margin-bottom: 30px;
  }
}

.comments-area .single-comment:not(.trackback):not(.pingback) .entry-content {
  max-width: calc(100% - 95px);
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .comments-area .single-comment:not(.trackback):not(.pingback) .entry-content {
    max-width: 100%;
  }
}

.comments-area .single-comment .entry-content .commented-on {
  font-size: 12px;
}

.comments-area .single-comment .entry-content p {
  margin: 7px 0 5px;
}

.comments-area .single-comment .entry-content .comment-reply-link {
  font-style: italic;
  font-size: 14px;
}

.comments-area ul.children {
  margin-left: 95px;
}

@media only screen and (max-width: 575px) {
  .comments-area ul.children {
    margin-left: 30px;
  }
}

/* Comments Form */
.comment-respond .comment-reply-title {
  margin-bottom: 20px;
}

.comment-respond .comment-form .comment-notes {
  margin-bottom: 30px;
}

.comment-respond .comment-form .theme-input-style, .comment-respond .comment-form .coronaDataTableWrapper .dataTables_filter input, .coronaDataTableWrapper .dataTables_filter .comment-respond .comment-form input {
  margin-bottom: 35px;
}

.comment-respond .comment-form .custom-checkbox {
  margin-bottom: 25px;
}

.comment-respond .custom-checkbox input {
  margin-right: 7px;
}

.pagination {
  margin-top: 20px;
}

.pagination li {
  margin: 0 10px;
}

.pagination li a {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #CC0C1B;
  border-radius: 5px;
}

.pagination li a.current, .pagination li a:hover {
  color: #ffffff;
}

/* ************************
   03.5: Footer
   ********************* */
.footer-top {
  padding: 35px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.footer-main {
  padding: 80px 0 50px;
}

.footer-main .widget {
  margin-bottom: 30px;
}

.footer-main .widget .widget-title {
  margin-bottom: 45px;
}

.footer-bottom {
  background-color: #07002b;
  padding: 26px 0;
}

.footer-menu li:not(:last-child):after {
  margin: 0 20px;
  content: "|";
  color: #ffffff;
}

.footer-menu li a {
  color: #ffffff;
}

.footer-menu li a:hover {
  opacity: 0.8;
}

/* ************************
   03.6: 404
   ********************* */
.content-404 h2 {
  font-weight: 500;
}

.content-404 h1 {
  font-size: 120px;
  line-height: 1.775;
  font-weight: 900;
}

@media only screen and (max-width: 991px) {
  .content-404 h1 {
    font-size: 60px;
    line-height: 1.2;
    margin: 20px 0;
  }
}

@media only screen and (max-width: 575px) {
  .content-404 h1 {
    font-size: 40px;
  }
}

/* ************************
   04.1: Symptoms
   ********************* */
.symptoms-wrapper figure {
  width: 158px;
  height: 718px;
}

@media only screen and (min-width: 1200px) {
  .symptoms-wrapper .card-wrapper {
    padding-left: 100px;
  }
  .symptoms-wrapper .card-wrapper .card {
    position: relative;
  }
  .symptoms-wrapper .card-wrapper .card .card-body {
    position: relative;
    z-index: 2;
    background-color: #ffffff;
  }
  .symptoms-wrapper .card-wrapper .card:before, .symptoms-wrapper .card-wrapper .card:after {
    position: absolute;
    content: '';
  }
  .symptoms-wrapper .card-wrapper .card:before {
    right: 100%;
    top: 50%;
    background-color: #E8EBF3;
    height: 5px;
    width: 100px;
    transform: translateY(-50%);
  }
  .symptoms-wrapper .card-wrapper .card:after {
    height: 20px;
    width: 20px;
    background-color: #CC0C1B;
    border-radius: 50%;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .symptoms-wrapper .card-wrapper .card:nth-child(1):before {
    width: 145px;
  }
  .symptoms-wrapper .card-wrapper .card:nth-child(3):before {
    width: 140px;
  }
}

/* ************************
   04.2: Contact
   ********************* */
.contact-form-wrapper {
  padding: 48px 35px;
}

.contact-form-wrapper .btn {
  padding: 21px 30px;
  font-size: 14px;
  font-weight: 700;
  margin-right: 20px;
}

.form-response {
  background-color: rgba(254, 169, 132, 0.5);
}

.form-response span {
  display: block;
  margin-top: 30px;
  padding: 10px 15px;
}
