/* ************************
   02.2: Buttons
   ********************* */
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: $medium;
    color: $white;
    padding: 21.5px 32px;
    line-height: 1;
    letter-spacing: 1.2px;
    white-space: nowrap;
    text-transform: uppercase;
    border-radius: 50px;
    @extend %c1-bg;
    @extend %trans3;
    &:hover {
        @extend %title_color-bg;
        color: $white;
    }
    &.btn-borderd {
      background-color: transparent;
      border: 1px solid $white;
      &:hover {
        @extend %c1-bg;
        @extend %c1-bo
      }
    }
}

.btn-play {
    height: 74px;
    width: 74px;
    border-radius: 50%;
    background-color: $white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:after {
        border: 8px solid rgba($color: $white, $alpha: .15);
        border-radius: 50%;
        content: '';
        display: block;
        position: absolute;
        left: -8px;
        top: -8px;
        width: calc(100% + 16px);
        height: calc(100% + 16px);
        z-index: -1;
    }
    &:hover {
        &:after {
            animation: ripple 1s linear infinite;
        }
    }
}

.btn-icon {
  @extend %title_font;
  font-size: 14px;
  font-weight: $bold;
  .icon {
    height: 22px;
    width: 22px;
    @extend %c1-bg;
    border-radius: 5px;
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

// Ripple
@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    border-color: transparent;
  }
}
@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    border-color: transparent;
  }
}


