/* ************************
   02.7: Card
   ********************* */
.card {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 5px 5px 60px rgba(#000000, .02);
    .card-body {
        padding: 35px 30px;
        span {
            text-transform: uppercase;
            font-size: 13px;
            font-weight: $semi-bold;
            letter-spacing: 3.2px;
            @extend %c1;
            @extend %title_font;
        }
        h3 {
            font-weight: $medium;
            margin: 10px 0 18px;
        }
    }
}

.card-wrapper {
    .card {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}