/* ************************
   03.3: Coron Update
   ********************* */
//Live Update
.corona-live-update {
    background-position: center;
    background-color: $white;
    border-radius: 10px;
    @include customMin(992) {
        > .row {
            > .col-lg {
                &:first-child {
                    flex: 0 0 20%;
                    max-width: 20%;
                }
                 &:last-child {
                    flex: 0 0 80%;
                    max-width: 80%;
                }
            }
           
        }
    }

    .live-update-title {
        min-height: 200px;
        h2 {
            font-size: 12px;
            font-weight: $medium;
            display: inline-block;
            border-radius: 0 50px 50px 0;
            @extend %title_color-bg;
            color: $white;
            margin-bottom: 5px;
            span:first-child {
                font-size: 24px;
                font-weight: $semi-bold;
                background-color: $white;
                border-radius: 0 50px 50px 0;
                padding-right: 5px;
                @extend %c1;
            }
            span:last-child {
                padding: 0 10px 0 5px;
            }
        }
        > span {
            display: block;
            font-size: 10px;
            font-weight: $medium;
            @extend %title_color;
            text-transform: uppercase;
            letter-spacing: 4.5px;
        }
    }

    .cu_info {
        min-height: 200px;
        h2 {
            font-size: 40px;
            font-weight: $semi-bold;
        }
        span {
            font-size: 21px;
            @extend %title_color;
        }
        &.cu_confirmed {
            background-color: rgba(#F78B00, .1);
            h2 {
                color: #F78B00;
            }
        }
        &.cu_recovered {
            background-color: rgba(#36C97B, .1);
            h2 {
                color: #36C97B;
            }
        }
        &.cu_deaths {
            background-color: rgba(#CC0C1B, .1);
            h2 {
                color: #CC0C1B;
            }
        }
        &.cu_countries {
            background-color: rgba(#1738D5, .1);
        }
    }
}

//Update Group
.corona-update-group {
    overflow: hidden;
    margin-top: 50px;
    @include customMin(992) {
        margin-top: -170px;
    }
    > .row {
        margin-right: -5px;
        margin-bottom: -4px;
        > .cu_info {
            min-height: 152px;
            border-width: 0 4px 4px 0;
            border-style: solid;
            border-color: #fff;
            span {
                font-size: 18px;
                font-weight: $extra-bold;
                margin-bottom: 10px;
            }
            h2 {
                font-family: $body-font;
                font-size: 36px;
                font-weight: $extra-bold;
            }
        }
    }  
}

//Single Country Update
.single-country {
    position: relative;
    cursor: pointer;
    .flag-wrapper {
        position: relative;
        z-index: 1;
        width: 60px;
        height: 60px;
        &:after {
            border: 10px solid rgba(255, 255, 255, 0.15);
            border-radius: 50%;
            content: '';
            display: block;
            position: absolute;
            left: -10px;
            top: -10px;
            width: calc(100% + 20px);
            height: calc(100% + 20px);
            z-index: -1;
            transition: .3s linear;
        }
        .flag-icon {
            height: 60px;
            width: 60px;
            overflow: hidden;
            border-radius: 50%;
            position: relative;
            &:after {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($color: #000000, $alpha: .6);
                content: '';
                @extend %trans3;
            }
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .country-name {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            line-height: 1;
            font-size: 10px;
            font-weight: $bold;
            text-transform: uppercase;
            @extend %title_font;
            color: #fff;
            @extend %trans3;
        }
    }

    .country-info{
        @extend %trans3;
        border-radius: 10px;
        overflow: hidden;
        li {
            padding: 7px 0;
            font-size: 13px;
            font-weight: $semi-bold;
            color: $white;
        }
    }

    @include customMin(1200) {
        .country-name,
        .country-info,
        .flag-wrapper .flag-icon:after {
            opacity: 0;
            visibility: hidden;
        }
        .country-info{
            display: flex;
            position: absolute;
            left: 50%;
            bottom: calc(100% + 30px);
            transform: translateX(-50%);
            border-radius: 50px;
            z-index: 9;
            width: 340px;
            height: 56px;
            backface-visibility: hidden;
            li {
                width: 33.33%;
                white-space: nowrap;
                text-align: center;
                &:first-child {
                    border-radius: 50px 0 0 50px;
                }
                &:last-child {
                    border-radius: 0 50px 50px 0;
                }
            
            }
        }

        &:hover {
            .country-name,
            .country-info,
            .flag-wrapper .flag-icon:after {
                opacity: 1;
                visibility: visible;
            }
            .flag-wrapper {
                &:after {
                    animation: ripple 1s linear infinite;
                }
            }
            .country-info {
                bottom: calc(100% + 20px);
            }
        }
    }
}

@include customMax(1350) {
    .corona-country-update {
        .col-xl {
            &:first-child {
                .country-info { 
                    left: 0;
                    transform: none;
                }
            }
        }
    }
}

//Update Table
.coronaDataTableWrapper {
    padding: 45px 20px;
    box-shadow: 5px 5px 60px rgba($color: #000000, $alpha: .05);
    .dataTables_length,
    .dataTables_filter label {
        @extend %title_font;
        @extend %title_color;
        font-size: 16px;
        font-weight: $semi-bold;
    }
    .dataTables_length {
        select {
            border: 1px solid #E1E5EA;
            border-radius: 5px;
            padding: 5px;
        }
    }
    .dataTables_filter {
        label {
            display: flex;
            align-items: center;
        }
        input {
            @extend .theme-input-style;
            height: 40px;
            border-color: #E1E5EA;
        }
    }
    .dataTables_length, 
    .dataTables_filter {
        margin-bottom: 20px;
    }
    .dataTables_paginate {
        .paginate_button {
            font-size: 15px;
            @extend %title_color;
            background: rgba(#000, .02);
            border: none;
            border-radius: 2px;
            &:not(.disabled) {
                &.current,
                &:hover {
                    color: $white !important;
                    background: $c1 !important;
                    border: none !important;
                }
            }
            &.disabled {
                opacity: .5;
            }
        } 
    }
}

table.coronaDataTable {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 20px;
    thead {
        th {
            text-transform: uppercase;
            @extend %title_color;
            @extend %title_font;
            padding: 20px;
            // border-bottom: 1px solid #F0F0F0;
        }
    }
    thead,tbody {
        th,td {
            border: 1px solid #F0F0F0;
            padding: 15px;
            text-align: center;
        }
    }
    .flag-img {
        width: 30px;
        height: 30px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        margin: auto;
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

