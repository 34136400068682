/* ************************
   03.6: 404
   ********************* */
.content-404 {
  h2 {
    font-weight: $medium;
  }
  h1 {
    font-size: 120px;
    line-height: 1.775;
    font-weight: $black;
    @include tab {
      font-size: 60px;
      line-height: 1.2;
      margin: 20px 0;
    }
    @include mobileMd {
      font-size: 40px;
    }
  }
}
