// Bootstrap 4 Variables
$enable-grid-classes:                         true !default;
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;

// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;


$positions: static, relative, absolute, fixed, sticky !default;

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;


// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 20px !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    5: ($spacer * .25),
    10: ($spacer * .5),
    15: ($spacer - 5),
    20: $spacer,
    30: ($spacer + 10),
    40: ($spacer * 2),
    50: ($spacer + 30)
  ),
  $spacers
);

$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;

// Colors
$c1: #CC0C1B;
$title_color: #111111;
$text_color: #666666;
$text_light: #707070;
$white: #ffffff;
$black_c: #000000;
$body_bg: #ffffff;

// Fonts
$heading_font: 'Poppins', sans-serif;
$body_font: 'Nunito', sans-serif;

// Font Weight
$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;

// Font Size
$base-font-size: 16px;
$h1_font_size: 40px;
$h2_font_size: 30px;
$h3_font_size: 24px;
$h4_font_size: 20px;
$h5_font_size: 18px;
$h6_font_size: 16px;

// Line Height
$base-line-height: 1.625;