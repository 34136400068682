//
// Screenreaders
//

.sr-only,
.screen-reader-text {
	@include sr-only();
}

.sr-only-focusable,
.screen-reader-text {
    @include sr-only-focusable();
}
