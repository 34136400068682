/* ************************
   02.3: Input
   ********************* */
.theme-input-style {
  display: block;
  width: 100%;
  height: 56px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 0 15px;
  background-color: transparent;
}

textarea.theme-input-style {
  padding-top: 15px;
  height: 200px;
}

.theme-input-lable {
  @extend %title_font;
  font-size: 16px;
  font-weight: $semi-bold;
  @extend %title_color;
  margin-bottom: 10px;
}

.theme-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  overflow: hidden;
  .theme-input-style {
    flex: 1 1 0%;
    min-width: 0;
    border: none;
    height: 60px;
    background-color: $white;
    border-radius: 0;
  }
  button {
    height: 60px;
    border-radius: 0 5px 5px 0;
  }
}
